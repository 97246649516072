import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Header from '@components/Header';

const Complaints = ({
    data: {
        page: {
            frontmatter: {title, text}
        }
    }
}) => {
    return (
        <Layout>
            <SEO title={`Complaints`}/>
            <div className={styles.complaintsWrapper}>
                <Header title={title} graphicName="/images/hero-image-2.svg" />
                <div className={styles.complaintsContent}>
                    {!!text && <div dangerouslySetInnerHTML={{ __html: text }} />}
                </div>
            </div>

            <Divider />
            <Divider />
        </Layout>
    )
}

export default Complaints

export const query = graphql`
    query complaintsPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: complaints_title
                text: complaints_text
            }
        }
    }
`;